export default {
    "About Expert": "Over Expert",
    "About us": "Over ons",
    "Add new address": "Nieuw adres",
    "Add product to cart": "Plaats in winkelwagen",
    "Add.": "Toevoeging",
    "Addition": "Toevoeging",
    "Address": "Adres",
    "Address info": "Adres gegevens",
    "All reviews": "Alle beoordelingen",
    "All stores with stock": "Alle winkels met voorraad",
    "Always up to date with the latest promotions": "Altijd op de hoogte van de nieuwste acties",
    "An error occurred during the payment process, please try again or use another payment method.": "Er is een fout opgetreden tijdens het betalingsproces, probeer het opnieuw of gebruik een andere betaalmethode.",
    "An unknown error has occurred.": "Er is een onbekende fout opgetreden.",
    "Apply": "Toepassen",
    "Ask a question about this product": "Stel een vraag over dit product",
    "Ask a question via Whatsapp or e-mail": "Stel een vraag via Whatsapp of e-mail",
    "Ask for more information": "Stel een vraag",
    "BTW": "Waarvan BTW",
    "Tax": "BTW",
    "Service Fee": "Servicekosten",
    "Back": "Terug",
    "Back to cart": "Terug naar winkelwagen",
    "Back to store page": "Terug naar winkelpagina",
    "Back to top": "Terug naar boven",
    "Billing address": "Factuuradres",
    "Billing address is different from the address above": "Factuuradres is anders dan het bovenstaande adres",
    "Bought product": "Product gekocht",
    "Bundle added to cart": "Bundel is toegevoegd aan winkelwagen",
    "Bundle discount": "Bundelkorting",
    "Bundledeals": "Bundeldeals",
    "Buy without a Expert account": "Snel afrekenen zonder te registreren",
    "Cancel": "Annuleren",
    "Cart": "Winkelwagen",
    "Categories": "Categorie\u00ebn",
    "Change": "Wijzigen",
    "Check stores stock": "Bekijk winkels met voorraad",
    "Choose a service": "Kies voor Expert Service",
    "Choose other shop": "Kies een andere winkel",
    "Choose the Expert store you want to contact or that delivered the product": "Kies de Expertwinkel waar u contact mee wilt of die het product geleverd heeft",
    "Choose your bank": "Kies een bank",
    "Choose your creditcard": "Kies een creditcard",
    "City": "Woonplaats",
    "Click here to automatically retrieve your address": "Klik hier om automatisch uw adres op te halen",
    "Click here to continue shopping.": "Klik hier om verder te winkelen.",
    "Click here to enter the address manually.": "Klik hier om het adres handmatig in te voeren.",
    "Closed": "Gesloten",
    "Closed on": "Gesloten op",
    "Closed today": "Vandaag gesloten",
    "Collect your order at": "Uw bestelling afhalen bij",
    "Comment": "Opmerking",
    "Company": "Bedrijfsnaam",
    "Compare": "Vergelijk",
    "Compare product": "Vergelijk product",
    "Compare products": "Vergelijk producten",
    "Complete order": "Bestellen en betalen",
    "Confirm password": "Bevestig wachtwoord",
    "Contactperson": "Contactpersoon",
    "Continue shopping": "Verder winkelen",
    "Copy the link below and then you can paste and share it.": "Kopieer onderstaande link en daarna kan je deze plakken en delen.",
    "Create a new account": "Maak een nieuw account aan",
    "Create account": "Account aanmaken",
    "Current stores stock": "Huidige winkelvoorraad",
    "Customer service": "Klantenservice",
    "Dashboard": "Overzicht",
    "Date": "Datum",
    "Delete": "Verwijderen",
    "Delivery in consultation. You will be contacted": "Bezorging in overleg. Er wordt contact met u opgenomen",
    "Describe your question and/or problem": "Omschrijf uw vraag en/of probleem",
    "Description": "Beschrijving",
    "Details": "Gegevens",
    "Discount": "Korting",
    "Do you have a promotional code?": "Heeft u een kortingscode?",
    "E-mailadress": "E-mailadres",
    "EAN": "EAN",
    "Email address": "E-mail",
    "Enjoy your order!": "Geniet van uw bestelling!",
    "Enter a correct value": "Voer een correcte waarde in",
    "Enter your postcode or city name and choose your shop.": "Vul je postcode of plaatsnaam in en kies je winkel.",
    "Expected delivery date": "Verwachte leverdatum",
    "Expert environment.": "Expert omgeving.",
    "Expert stores": "Expert winkels",
    "Fields marked with * are required.": "Velden gemarkeerd met * zijn verplicht.",
    "Find a Expert shop": "Vind uw Expert",
    "Find a shop near you": "Vind een winkel bij jou in de buurt",
    "First name": "Voornaam",
    "Firstname": "Voornaam",
    "Folder": "Folder",
    "Follow us on social media": "Volg ons via social media",
    "For orders from \u20ac{amount}": "Bij bestellingen vanaf \u20ac{amount}",
    "Forgot password?": "Wachtwoord vergeten?",
    "Free": "Gratis",
    "Free delivery": "Gratis levering",
    "Free pick-up": "Gratis afhalen",
    "Free shipping or pick up": "Gratis verzending of afhalen",
    "Friday": "Vrijdag",
    "General": "Algemeen",
    "Go to the cart": "Naar winkelwagen",
    "Good day {name}!": "Goedendag {name}!",
    "Hi": "Hallo",
    "Hit enter or try another search.": "Druk op enter en probeer een andere zoekopdracht",
    "House Nr add.": "Toevoeging",
    "House Nr.": "Huisnummer",
    "House no.": "Huisnummer",
    "House number": "Huisnummer",
    "How many stars would you give this product": "Hoeveel sterren zou je dit product geven",
    "In one of our {stores} stores": "In \u00e9\u00e9n van onze {stores} winkels",
    "Invalid address": "Het ingevoerde adres is onbekend",
    "Invalid address. Are you sure it is correct?": "Ongeldig adres. Weet u zeker dat het correct is?",
    "Is this product expected at the Expert store near you?": "Wordt dit product verwacht bij de Expert winkel bij u in de buurt?",
    "Is your Expert store of choice not listed?": "Staat uw Expert winkel naar keuze er niet tussen?",
    "Last name": "Achternaam",
    "Last review by": "Laatste beoordeling door",
    "Lastname": "Achternaam",
    "Load more reviews": "Bekijk meer reviews",
    "Loading address...": "Adres ophalen...",
    "Log in": "Inloggen",
    "Log in with your Expert account": "Log in met je Expert account",
    "Login": "Inloggen",
    "Matching accessories": "Bijpassende accessoires",
    "Monday": "Maandag",
    "More about": "Meer over",
    "More information": "Meer informatie",
    "More then {stores} shops in the Netherlands": "Meer dan {stores} winkels in Nederland",
    "My account": "Mijn account",
    "My orders": "Mijn bestellingen",
    "My store orders": "Mijn winkelbestellingen",
    "Name": "Naam",
    "Navigation": "Navigatie",
    "Need personalised advice?": "Persoonlijk advies nodig?",
    "Newsletter": "Nieuwsbrief",
    "No Expert account yet?": "Nog geen Expert account?",
    "No product specifications found.": "Geen producten gevonden.",
    "No products were found for &ldquo;{term}&ldquo;": "Er zijn geen producten gevonden voor &ldquo;{term}&rdquo;",
    "No products were found in this category.": "Er zijn geen producten gevonden in deze categorie.",
    "No stores with stock found": "Geen winkels met voorraad gevonden",
    "Number of stars are missing": "Aantal sterren ontbreekt",
    "Online, we apply a maximum of {amount}, click <a href='{amount}URL'>here</a> for more info": "Online hanteren wij een maximum van {amount}, klik <a href='{amount}URL'>hier</a> voor meer info",
    "Open today to {time}": "Vandaag open tot {time}",
    "Opening hours": "Openingstijden",
    "Openings hours": "Openingstijden",
    "Opens {day} at {time}": "Opent {day} om {time}",
    "Opens today at {time}": "Vandaag geopend om {time}",
    "Or in 3 instalments of": "Of in 3 termijnen van",
    "Order as a company": "Bestellen als een bedrijf",
    "Overview of your order": "Overzicht van uw bestelling",
    "Password": "Wachtwoord",
    "Payed": "Betaald",
    "Payment": "Betalen",
    "Payment methods": "Betaalmethoden",
    "Personal info": "Persoonlijke gegevens",
    "Phone number": "Telefoonnummer",
    "Pick up from": "Afhalen vanaf",
    "Place review": "Review plaatsen",
    "Please fill in a correct E-mail address": "E-mail moet een geldig e-mailadres zijn",
    "Please select a shipping method.": "Selecteer een verzendmethode of winkel.",
    "Please wait, loading payment methods": "Even geduld, betaalmethoden worden geladen",
    "Please wait, loading shipping methods": "Even geduld, verzendmethoden worden geladen",
    "Please wait, your coupon will be checked": "Even geduld, uw coupon wordt gecontroleerd",
    "Popular products": "Populaire producten",
    "Postal code": "Postcode",
    "Postcode": "Postcode",
    "Power label": "Energielabel",
    "Price": "Prijs",
    "Price incl.": "Prijs incl.",
    "Product added to cart": "Product is toegevoegd aan de winkelwagen",
    "Product information": "Product informatie",
    "Product is in stock": "Product is op voorraad",
    "Product is out of stock": "Product is niet op voorraad",
    "Product number": "Artikelnummer",
    "Product purchased": "Product gekocht",
    "Product specifications": "Productspecificaties",
    "Productreviews": "Productreviews",
    "Products from a previous shopping session have been added to your shopping cart. <a href='/checkout/cart'>View products.</a>": "Producten uit een eerdere winkelsessie zijn toegevoegd aan uw winkelwagen. <a href='/checkout/cart'>Bekijk producten.</a>",
    "Products per page": "Producten per pagina",
    "Questions about ordering?": "Vragen over het bestellen?",
    "Read more": "Lees meer",
    "Realisation:": "Realisatie:",
    "Reset password": "Wachtwoord resetten",
    "Results for \"{param}\"": "Resultaten voor \"{param}\"",
    "Review": "Beoordeling",
    "Review has been posted, after checking we will publish your review": "Review is geplaatst, na controle zullen wij uw review publiceren.",
    "Reviews": "Beoordelingen",
    "Saturday": "Zaterdag",
    "Search for...": "Zoeken naar...",
    "Search results for &ldquo;{term}&ldquo;": "Zoekresultaten voor &ldquo;{term}&ldquo;",
    "Search store": "Zoek winkel",
    "Searching": "Zoeken",
    "Select a store": "Maak een keuze",
    "Select a subject": "Maak een keuze",
    "Select your address": "Selecteer jouw adres",
    "Selected services": "Gekozen servicediensten",
    "Selected store": "Geselecteerde winkel",
    "Share this product": "Deel dit product",
    "Shipment price": "Verzending",
    "Shipping": "Verzending",
    "Shipping & Handling": "Verzending",
    "Shipping cost will be calculated in the next step": "Verzendkosten worden berekend tijdens het afrekenen",
    "Shipping methods": "Verzendmethoden",
    "Shop safely with us": "Bij ons winkelt u veilig",
    "Show all results for": "Toon alle resultaten voor",
    "Show differences": "Toon verschillen",
    "Show less": "Minder tonen",
    "Show more": "Meer tonen",
    "Sign out": "Uitloggen",
    "Something went wrong": "Er is iets misgegaan",
    "Something went wrong while creating your account, please try again later.": "Er is iets misgegaan bij het aanmaken van je account, probeer het later opnieuw.",
    "Something went wrong, the payment could not be completed. Please try again or choose a different payment method.": "Er is iets misgegaan, de betaling kon niet worden voltooid. Probeer het opnieuw of kies een andere betaalmethode.",
    "Sponsored product": "product gesponsord",
    "Store": "Winkel",
    "Stores": "Winkels",
    "Street": "Straatnaam",
    "Subject": "Onderwerp",
    "Submit contact request": "Verstuur contactverzoek",
    "Subtotal": "Subtotaal",
    "Suggestions": "Zoeksuggesties",
    "Sunday": "Zondag",
    "Telephone": "Telefoonnummer",
    "Tested product": "product getest",
    "Thank you for your order!": "Bedankt voor uw bestelling!",
    "The CAPTCHA thinks you are a robot! Please refresh and try again.": "De CAPTCHA denkt dat u een robot bent! Vernieuw de pagina en probeer het opnieuw.",
    "The account sign-in was incorrect or your account is disabled temporarily. Please wait and try again later.": "Het aanmelden voor het account was onjuist of uw account is tijdelijk uitgeschakeld. Wacht even en probeer het later opnieuw.",
    "The delivery date cannot be calculated at this time, the Expert Shop will contact you": "De bezorgdatum kan op dit moment niet berekend worden, de Expertwinkel neemt contact met u op",
    "The gift card does not exist, please try again.": "De cadeaukaart bestaat niet, probeer het opnieuw.",
    "The order is being checked and prepared": "De bestelling wordt gecontroleerd en klaargemaakt",
    "The payment is canceled, the order is not paid": "De betaling is geannuleerd, de bestelling is niet betaald",
    "The payment provider rejected the payment request, please use another payment method.": "De betaalprovider heeft het betalingsverzoek afgewezen, gebruik een andere betaalmethode.",
    "The pin code is incorrect, please try again.": "De pincode is onjuist, probeer het opnieuw.",
    "The product may still be in stock in our central warehouse. You can simply order the product, the Expert Shop will contact you after your online order to agree on a delivery or collection time.": "Het product kan nog steeds op voorraad zijn in ons centrale magazijn. U kunt het product eenvoudig bestellen, de Expert Shop neemt na uw online bestelling contact met u op om een bezorg- of afhaaltijd af te spreken.",
    "The requested qty exceeds the maximum qty allowed in shopping cart": "De gevraagde hoeveelheid is groter dan de maximaal toegestane hoeveelheid in het winkelwagentje",
    "The store can inform you about the expected delivery time.": "De winkel kan u informeren over de verwachte levertijd.",
    "The store was not found": "De winkel is niet gevonden",
    "There are no reviews for this store": "Er zijn geen reviews voor deze winkel",
    "This is a secure connection": "Dit is een veilige verbinding",
    "This personal page offers you an overview of the most recent activities. You can, among other things, view orders and manage your personal settings. Click a link below to get started!": "Deze persoonlijke pagina biedt je een overzicht van de meest recente activiteiten. Je kunt onder andere bestellingen bekijken en je persoonlijke instellingen beheren. Klik op een link hieronder om aan de slag te gaan!",
    "This postcode is unknown": "Deze postcode is onbekend",
    "This store will process your order and ensure that your order is delivered. Delivery is done by Expert itself or another carrier.": "Deze winkel zal uw bestelling verwerken en ervoor zorgen dat uw bestelling wordt geleverd. De levering wordt gedaan door Expert zelf of een andere vervoerder.",
    "Thursday": "Donderdag",
    "Title": "Titel",
    "To payment": "Naar betaling",
    "Total Paid": "Betaald",
    "Trusted payment": "Vertrouwd betalen",
    "Tuesday": "Dinsdag",
    "Unable to place review": "De review kan niet worden geplaatst",
    "Used product": "product gebruikt",
    "VAT": "BTW",
    "View all": "Bekijk alle",
    "View all specifications": "Bekijk alle specificaties",
    "View delivery options and delivery times in your area": "Bekijk de bezorgopties en tijden in jouw buurt",
    "View everything from": "Bekijk alles van",
    "View other stores": "Bekijk ook andere winkels",
    "Visit our storepage": "Bezoek deze winkelpagina",
    "We also recommend": "Wij bevelen ook aan",
    "We could not load the payment methods, please check your shipping method.": "We konden de betaalmethoden niet laden, controleer de verzendmethode.",
    "We have found the following address": "We hebben het volgende adres gevonden",
    "We recieved your order": "Uw bestelling is bij ons binnengekomen",
    "We will contact you after your online order to arrange a collection time.": "Wij nemen na uw online bestelling contact met u op om een afhaalmoment/-tijd af te spreken.",
    "Wednesday": "Woensdag",
    "Welcome back": "Welkom terug",
    "Welcome to your": "Welkom bij je",
    "What do our customers say": "Wat zeggen onze klanten",
    "What else can we do for you?": "Wat kunnen wij nog meer voor u doen?",
    "What to expect from us?": "Wat kunt u van ons verwachten?",
    "Where can we help you with?": "Waar kunnen we u mee helpen?",
    "Why order from Expert?": "Waarom bestellen bij Expert?",
    "With 140 stores always nearby, select the Expert store near you": "Met 140 winkels altijd vlakbij, selecteer de Expert winkel bij u in de buurt",
    "Without extra fees": "Zonder extra kosten",
    "Written by": "Geschreven door",
    "Yes, I would like to receive the newsletter": "Ja, ik wil de nieuwsbrief ontvangen",
    "You are sign up for our newsletter with {email}": "Je hebt je aangemeld met {email}",
    "You are subscribed!": "Gelukt!",
    "You can pick up your order at": "U kunt uw bestelling ophalen bij",
    "You have an account. Would you like to login?": "Je hebt een account. Wil je inloggen?",
    "You have no product(s) in your shopping cart.": "U heeft geen product(en) in uw winkelwagen.",
    "You might also like": "Meepakkers!",
    "You will receive an email confirming your order with all the details within 15 minutes. Also check your spam folder if you have not received a confirmation email after 15 minutes. If you have any questions about the status of your order, it is best to contact customer service.": "U ontvangt binnen 15 minuten een e-mail ter bevestiging van uw bestelling met alle gegevens. Kijk ook in uw spamfolder als u na 15 minuten geen bevestigingsmail heeft ontvangen. Heeft u vragen over de status van uw bestelling, dan kunt u het beste contact opnemen met de klantenservice.",
    "Your account has been created, here you can view your order and track the progress of the order. Click on login to view your account.": "Je account is aangemaakt, hier kun je je bestelling bekijken en de voortgang van de bestelling volgen. Klik op inloggen om je account te bekijken.",
    "Your account has been successfully created!": "Je account is succesvol aangemaakt!",
    "Your applied coupon code is {couponCodeInput}.": "Uw toegepaste kortingscode is {couponCodeInput}.",
    "Your info": "Uw gegevens",
    "Your order has been successfully placed.": "Je bestelling is succesvol geplaatst.",
    "Your order number is: #{order}Id.": "Uw ordernummer is: #{order}Id.",
    "Your order will be delivered by": "Uw bestelling wordt bezorgd door",
    "Your order will be delivered on": "Uw bestelling wordt bezorgd op",
    "Your order will be delivered or Expert will let you know when your order is ready": "Uw bestelling wordt bezorgd of Expert laat het weten als uw bestelling klaar ligt",
    "Your order will be delivered to the address below": "Uw bestelling wordt bezorgd op het onderstaande adres",
    "Your order will be taken care of by": "Uw bestelling wordt verzorgd door",
    "Your personal data": "Uw persoonlijke gegevens",
    "Your postal code": "Uw postcode",
    "Your request has been sent, we will get back to you as soon as possible!": "Je verzoek is verstuurd, we komen hier zo spoedig mogelijk op terug!",
    "average": "gemiddeld",
    "by Klarna": "via Klarna",
    "explain": "uitleg",
    "home copying levy:": "thuiskopieheffing:",
    "is mine Expert!": "is mijn Expert!",
    "km away from you.": "km bij mij vandaan.",
    "out of 5": "van de 5",
    "services_fee": "Kosten Servicedienst(en)",
    "services_fee_incl_tax": "Kosten Servicedienst(en)",
    "star": "ster",
    "stars": "sterren",
    "terms and conditions": "voorwaarden",
    "validation.email": "Vul een geldig e-mailadres in",
    "validation.required": "{attribute} is een verplicht veld",
    "processing": "Bezig met verwerken",
    "pending": "Wachten op betaling",
    "reserveren": "Afhalen in winkel",
    "canceled": "Geannuleerd",
    "complete": "Afgerond",
    "Products from a previous shopping session have been added to your cart": "Producten uit een eerdere winkelsessie zijn toegevoegd aan uw winkelwagen. <a href='/checkout/cart'>Bekijk producten.</a>",
    "Password reset failed, please try again later.": "Je wachtwoord moet minimaal 8 tekens lang zijn en minstens \u00e9\u00e9n kleine letter, \u00e9\u00e9n hoofdletter, \u00e9\u00e9n cijfer en \u00e9\u00e9n speciaal teken bevatten.",
    "Minimum of different classes of characters in password is 3. Types of characters: lowercase, uppercase, numbers and special characters.": "Je wachtwoord moet minstens \u00e9\u00e9n kleine letter, \u00e9\u00e9n hoofdletter, \u00e9\u00e9n cijfer en \u00e9\u00e9n speciaal teken bevatten.",
    "Read less": "Lees minder",
    "Could not verify customer with given information.": "Wij kunnen uw account helaas niet activeren.",
    "Your account has been verified.": "Je account is gevalideerd en kunt nu inloggen.",
    "To shipping": "Naar bezorg- en afhaalopties"
}