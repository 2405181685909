const initialState = () => {
  return {
    isB2B: window.Config['is_b2b'] || false,
    isLoggedIn: window.Config['is_logged_in'] || false,
  };
};

const state = initialState();

const GlobalData = {
  namespaced: true,
  state,
};

export default GlobalData;
