import {CustomerAddress, CustomerBillingAddress, CustomerShippingAddress} from "../../shared/helpers/quote/address";

type QuoteState = {
  quote: object,
}

const initialState = (): QuoteState => {
  return {
    quote: {},
  };
};

const getters = {
  addresses: (state): CustomerAddress[] => state.quote['customer']['addresses'] || [],
  defaultBillingAddressId (state): null|number {
    const defaultAddress = state.quote['customer']['default_billing'] || null;
    return null !== defaultAddress ? parseInt(defaultAddress) : null;
  },
  defaultShippingAddressId (state): null|number {
    const defaultAddress = state.quote['customer']['default_shipping'] || null;
    return null !== defaultAddress ? parseInt(defaultAddress) : null;
  },
  items: (state): object[] => state.quote['items'] || [],
  itemsCount: (state): number => state.quote['items_count'] || 0,
  itemsQty: (state): number => state.quote['items_qty'] || 0,
};

const mutations = {
  SET_QUOTE(state, quote: object): void {
    state.quote = quote;
  }
};

const actions = {
  async fetchQuote({commit}): Promise<void> {
    try {
      const {data: quote} = await this.$solarClient.get('/api/quote');
      commit('SET_QUOTE', quote);
    } catch (e) {
      console.error(e);
    }
  },
  async addQuoteItem({dispatch}, payload: object): Promise<object> {
    let servicesData: null|object = null;

    const {data: quoteItem} = await this.$solarClient.post('/api/quote/item', {
      cartItem: payload,
    });

    servicesData = {
      cartItemId: quoteItem['item_id'],
      productSku: quoteItem['sku'],
      index: 0,
    };

    if (servicesData) {
      await dispatch("ProductServices/addServiceToQuote", servicesData, {root: true});
    }

    return quoteItem;
  },
  async addQuoteItemWithTotalsRecollect({dispatch}, payload: object): Promise<void> {
    await dispatch('addQuoteItem', payload);
    await dispatch('CheckoutTotals/fetchCartTotals', null, {root: true});
  },
  async updateQuoteItem({dispatch}, payload: object): Promise<void> {
    try {
      await this.$solarClient.put(`api/quote/item/${payload['item_id']}`, {qty: payload['qty']});
    } catch (e) {
      console.error(e);
      return;
    }

    await Promise.all([
      dispatch('fetchQuote'),
      dispatch('CheckoutTotals/fetchCartTotals', null, {root: true})
    ]);
  },
  async deleteQuoteItem({dispatch}, itemId: number): Promise<void> {
    try {
      await this.$solarClient.delete(`api/quote/item/${itemId}`);
    } catch (e) {
      console.error(e);
      return;
    }

    await Promise.all([
      dispatch('fetchQuote'),
      dispatch('CheckoutTotals/fetchCartTotals', null, {root: true})
    ]);
  },
  async setAddressesOnQuote({commit}, addresses: {billing_address: CustomerBillingAddress, shipping_address: CustomerShippingAddress}): Promise<boolean> {
    const {billing_address: billingAddress, shipping_address: shippingAddress} = addresses;

    delete billingAddress['id'];
    delete billingAddress['default_billing'];

    delete shippingAddress['id'];
    delete shippingAddress['default_shipping'];

    try {
      const {data: quote} = await this.$solarClient.put('/api/quote/address/set-addresses', {
        billing_address: billingAddress,
        shipping_address: shippingAddress
      })

      commit('CheckoutAddress/SET_BILLING_ADDRESS', quote['billing_address'], {root: true})
      commit('CheckoutAddress/SET_SHIPPING_ADDRESS', quote['extension_attributes']['shipping_assignments'][0]['shipping']['address'], {root: true})

      return true;
    } catch (e) {
      console.error(e)
      return false;
    }
  },
  async setBillingAddressOnQuote({commit}, address: object): Promise<boolean> {
    delete address['id']
    delete address['default_billing']

    try {
      const {data: quote} = await this.$solarClient.put('/api/quote/address/set-billing-address', address)

      commit('SET_BILLING_ADDRESS', quote['billing_address'])

      return true
    } catch (e) {
      console.error(e)
      return false
    }
  },
  async setShippingAddressOnQuote({commit}, address: object): Promise<boolean> {
    delete address['id']
    delete address['default_shipping']

    try {
      const {data: quote} = await this.$solarStatefulClient.put('/api/quote/address/set-shipping-address', {address})

      commit('SET_SHIPPING_ADDRESS', quote['extension_attributes']['shipping_assignments'][0]['shipping']['address'])

      return true
    } catch (e) {
      console.error(e)
      return false
    }
  },
};

const state: QuoteState = initialState();

const Quote = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default Quote;
