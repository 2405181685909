import { appSessionStorage } from '../services';

type storeState = {
  couponCode: string,
  couponStatus: boolean,
  couponMessage: null|string,
  couponRequestIsLoading: boolean,
  paymentMethods: object[],
  loadingPaymentData: boolean,
  selectedPaymentMethod: null|object,
  paymentDetails: object,
  shippingOptions: object,
};

const initialState = (): storeState => {
  return {
    couponCode: appSessionStorage.getItem('checkout-payment-coupon-code', ''),
    couponStatus: false,
    couponMessage: '',
    couponRequestIsLoading: false,

    paymentMethods: [],
    loadingPaymentData: false,
    selectedPaymentMethod: appSessionStorage.getItem('checkout-payment-selected-method', null),

    paymentDetails: appSessionStorage.getItem('checkout-payment-details', {}),
    shippingOptions: appSessionStorage.getItem('checkout-shipping-options', {}),
  };
};

const mutations = {
  SET_COUPON_CODE(state, couponCode) {
    state.couponCode = couponCode;
    appSessionStorage.setItem('checkout-payment-coupon-code', couponCode);
  },
  SET_COUPON_STATUS(state, couponStatus) {
    state.couponStatus = couponStatus;
  },
  SET_COUPON_MESSAGE(state, couponMessage) {
    state.couponMessage = couponMessage;
  },
  SET_COUPON_REQUEST_IS_LOADING(state, couponRequestIsLoading) {
    state.couponRequestIsLoading = couponRequestIsLoading;
  },
  SET_PAYMENT_METHODS(state, paymentMethods) {
    state.paymentMethods = paymentMethods;
  },
  SET_PAYMENT_METHOD_CONFIG(state, paymentMethodConfig) {
    state.paymentMethodConfig = paymentMethodConfig;
  },
  SET_LOADING_PAYMENT_DATA(state, loadingPaymentData) {
    state.loadingPaymentData = loadingPaymentData;
  },
  SET_SELECTED_PAYMENT_METHOD(state, selectedPaymentMethod) {
    state.selectedPaymentMethod = selectedPaymentMethod;
    appSessionStorage.setItem('checkout-payment-selected-method', selectedPaymentMethod);
  },
  CLEAR_PAYMENT_DETAILS(state) {
    state.paymentDetails = {};
    appSessionStorage.removeItem('checkout-payment-details');
  },
  ADD_PAYMENT_DETAILS(state, paymentDetail) {
    state.paymentDetails = { ...state.paymentDetails, ...paymentDetail };
    appSessionStorage.setItem('checkout-payment-details', state.paymentDetails);
  },
  REMOVE_PAYMENT_DETAILS(state, paymentDetail) {
    if (state.paymentDetails.hasOwnProperty(paymentDetail)) {
      delete state.paymentDetails[paymentDetail];
    }
    appSessionStorage.setItem('checkout-payment-details', state.paymentDetails);
  },
  CLEAR_SHIPPING_OPTIONS(state) {
    state.shippingOptions = {};
    appSessionStorage.removeItem('checkout-shipping-options');
  },
  ADD_SHIPPING_OPTIONS(state, shippingOption) {
    state.shippingOptions = { ...state.shippingOptions, ...shippingOption };
    appSessionStorage.setItem('checkout-shipping-options', state.shippingOptions);
  },
  REMOVE_SHIPPING_OPTIONS(state, shippingOption) {
    if (state.shippingOptions.hasOwnProperty(shippingOption)) {
      delete state[shippingOption];
    }
    appSessionStorage.setItem('checkout-shipping-options', state.shippingOptions);
  },
};

const actions = {
  async setPaymentMethod({ rootGetters, commit }, paymentMethod: object): Promise<void> {
    commit('CheckoutTotals/SET_PENDING_CHANGES', true, { root: true });

    const [_billingAddress, _shippingAddress] = rootGetters['CheckoutAddress/getAddressData'];

    try {
      const {data: totals} = await this.$solarClient.post(`/api/checkout/payment/setPaymentInformation`, {
        email: rootGetters['CheckoutGlobal/customerEmail'],
        shippingOptions: {},
        paymentMethod: {
          method: paymentMethod['code'],
          additional_data: {},
          extensionAttributes: {}
        },
        billingAddress: _billingAddress,
      });

      commit('CheckoutTotals/SET_TOTALS', totals, {root: true});
    } catch (err) {
      console.error(err)
    }

    commit('CheckoutTotals/SET_PENDING_CHANGES', false, { root: true });
  },
  async collectPaymentMethods({rootState, rootGetters, commit, state}): Promise<void> {
    commit('SET_LOADING_PAYMENT_DATA', true);

    const {method_code: shippingMethodCode, carrier_code: shippingCarrierCode} = rootState['CheckoutShipping']['shippingMethod'];
    const [billingAddress, shippingAddress] = rootGetters['CheckoutAddress/getAddressData'];
    const extensionAttributes = rootGetters['CheckoutGlobal/getExtensionAttributes'];

    delete billingAddress['id'];
    delete shippingAddress['id'];

    const {data} = await this.$solarClient.post('/api/checkout/payment/collectPaymentMethods', {
      addressInformation: {shippingAddress, billingAddress, shippingMethodCode, shippingCarrierCode, extensionAttributes},
    });

    commit('SET_PAYMENT_METHODS', data['payment_methods']);
    commit('SET_PAYMENT_METHOD_CONFIG', data['payment_config'] || {});

    if (null === state.selectedPaymentMethod) {
      commit('SET_SELECTED_PAYMENT_METHOD', data['payment_methods'][0]);
    }

    commit('CheckoutTotals/SET_TOTALS', data['totals'], {root: true});

    commit('SET_LOADING_PAYMENT_DATA', false);
  },
  async placeOrder({ state, rootGetters }) {
    const [billingAddress,] = rootGetters['CheckoutAddress/getAddressData'];

    return await this.$mageClient.post('/api/checkout/payment/placeOrder', {
      email: rootGetters['CheckoutGlobal/customerEmail'],
      shippingOptions: state.shippingOptions,
      paymentMethod: {
        method: state.selectedPaymentMethod['code'],
        additional_data: state.paymentDetails,
      },
      billingAddress
    });
  },
  async getCouponCode({ commit }): Promise<boolean> {
    try {
      const { data } = await this.$mageClient.get('/api/checkout/payment/coupons');

      if (data.length) {
        commit('SET_COUPON_CODE', data);
        commit('SET_COUPON_STATUS', true);
        return true;
      }

      return false;
    } catch (e) {
      return false;
    }
  },
  async applyCouponCode({ commit, dispatch }, couponCode: string): Promise<void> {
    commit('SET_COUPON_STATUS', false);
    commit('SET_COUPON_REQUEST_IS_LOADING', true);

    try {
      const { data: couponStatus } = await this.$mageClient.post(`/api/checkout/payment/coupons`, {
        couponCode
      });

      if (couponStatus) {
        commit('SET_COUPON_CODE', couponCode);
        commit('SET_COUPON_STATUS', true);
      }
    } catch ({ response }) {
      commit('SET_COUPON_MESSAGE', response.data.message);
    }

    try {
      await dispatch('CheckoutTotals/fetchCartTotals', null, {root: true})
    } catch (e) {
      console.error(e)
    }

    commit('SET_COUPON_REQUEST_IS_LOADING', false);
  },
  async removeCouponCode({ commit, dispatch }): Promise<void> {
    commit('SET_COUPON_REQUEST_IS_LOADING', true);

    try {
      await this.$mageClient.delete(`/api/checkout/payment/coupons`);
      commit('SET_COUPON_CODE', '');
      commit('SET_COUPON_STATUS', false);
    } catch (e) {
      commit('SET_COUPON_MESSAGE', 'The coupon code could not be cancelled, please try again later.');
    }

    try {
      await dispatch('CheckoutTotals/fetchCartTotals', null, {root: true})
    } catch (e) {
      console.error(e)
    }

    commit('SET_COUPON_REQUEST_IS_LOADING', false);
  },
};

const state: storeState = initialState();

const CheckoutPayment = {
  namespaced: true,
  state,
  actions,
  mutations,
};

export default CheckoutPayment;
