import { appSessionStorage } from '../services';
import {createAddressFromSource} from "../../../shared/helpers/quote/address";

type storeState = {
  shippingMethod: null|object,
  shippingMethods: object[],
  shippingCountries: object[],
  loadingShippingMethods: boolean,
  pblResponse: null|object,
};

const initialState = (): storeState => {
  return {
    shippingMethod: appSessionStorage.getItem('checkout-shipping-selected-method', null),
    shippingMethods: [],
    shippingCountries: [],
    loadingShippingMethods: false,
    pblResponse: null
  };
};

const getters = {
  pblShippingNextStepAllowed: (state) => null !== state.pblResponse && (state.pblResponse['allowNextStep'] || false),
  isShippingMethodSet: (state) => {return !!state.shippingMethod},
};

const mutations = {
  SET_SHIPPING_METHOD: (state, payload) => (state.shippingMethod = payload),
  SET_SHIPPING_METHODS: (state, payload) => (state.shippingMethods = payload),
  SET_SHIPPING_COUNTRIES: (state, payload) => (state.shippingCountries = payload),
  SET_LOADING_SHIPPING_METHODS: (state, payload) => (state.loadingShippingMethods = payload),
  SET_PBL_RESPONSE: (state, response) => (state.pblResponse = response)
};

const actions = {
  async setShippingMethod({ commit }, shippingMethod): Promise<void> {
    commit('SET_SHIPPING_METHOD', shippingMethod);
    appSessionStorage.setItem('checkout-shipping-selected-method', shippingMethod);
  },
  async collectShippingMethods({ rootGetters, commit, state }): Promise<void> {
    const [, shippingAddress] = rootGetters['CheckoutAddress/getAddressData'];

    const setAddressInformation = async (address: object): Promise<void> => {
      commit('SET_LOADING_SHIPPING_METHODS', true);

      try {
        const {data: shippingMethods} = await this.$solarClient.post('/api/checkout/shipping/collectShippingMethods', {
          address: createAddressFromSource(address)
        });
        commit('SET_SHIPPING_METHODS', [...shippingMethods]);
      } catch (e) {
        console.error(e);
      }

      commit('SET_LOADING_SHIPPING_METHODS', false);
    };

    await setAddressInformation({...shippingAddress});

    if ( ! state.pblResponse && state.shippingMethods.length) {
      commit('SET_SHIPPING_METHOD', state.shippingMethods[0])
    }
  },
  buildShippingRequest({state, rootGetters}) {
    const shippingAddress = rootGetters['CheckoutAddress/shippingAddress'];
    const customerEmail = rootGetters['CheckoutGlobal/customerEmail'];
    const cartItems: object[] = rootGetters['CheckoutTotals/items'];

    const address = {
      salutation: shippingAddress.prefix || '',
      firstName: shippingAddress.firstname || '',
      surNamePrefix: shippingAddress.middlename || '',
      surName: shippingAddress.lastname || '',
      companyName: shippingAddress.company || '',
      street: shippingAddress.street[0],
      houseNr: String(shippingAddress.street[1]),
      houseNrSuffix: shippingAddress.street[2],
      zipcode: shippingAddress.postcode,
      city: shippingAddress.city,
      country: shippingAddress.country_id,
      email: customerEmail || '',
    };

    const products: object[] = cartItems.flatMap((item: object) => {
      const _item = {...item};

      if ((_item['extension_attributes']['bundle_items'] || []).length > 0) {
        return _item['extension_attributes']['bundle_items'].map((bundleItem: object) => ({
          productnr: bundleItem['sku'],
          quantity: _item['qty'],
        }));
      }

      return [{
        productnr: _item['extension_attributes']['sku'],
        quantity: _item['qty'],
      }]
    });

    let services: object[] = []

    cartItems.forEach((item: object): void => {
      (item['extension_attributes']['services'] || '').split(',').forEach((sku: string): void => {
        if ( ! sku.length) {
          return
        }

        const existingItem: null|object = services.find((row: object): boolean => row['productnr'] === sku)

        if (existingItem) {
          existingItem['quantity'] += 1
          return
        }

        services.push({productnr: sku, quantity: 1})
      })
    })

    const mergedProducts: object[] = [...products, ...services]

    let preSelect = {};

    if (state['pblResponse']) {
      preSelect = {
        method: state['pblResponse']['method'] || '',
        deliveryDate: state['pblResponse']['promisedDeliveryDate'] || '',
        storeId: state['pblResponse']['store']['identifier'] || ''
      };
    }

    let freeShipping = state['shippingMethods'].filter((method) => {
      return method['amount'] > 0
    }).length === 0;

    return {
      freeShipping: freeShipping,
      address: address,
      products: mergedProducts,
      preSelect: preSelect
    };
  },
  setPblResponse({commit, dispatch}, payload) {
    commit('SET_PBL_RESPONSE', payload.response)

    let pblData = payload.response['store'] || {};

    if (null !== payload.response) {
      pblData['method'] = payload.response['method'];
      pblData['promisedDeliveryDate'] = payload.response['promisedDeliveryDate'];
    }

    dispatch('CheckoutGlobal/addExtensionAttribute', {
      pbl_selected_store: null !== pblData ? JSON.stringify(pblData) : null
    }, {root: true})
  }
};

const state: storeState = initialState();

const CheckoutShipping = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default CheckoutShipping;
