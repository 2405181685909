type TotalsState = {
  totals: object,
  itemsPendingChanges: number[],
  pendingChanges: boolean,
};

const initialState = (): TotalsState => {
  return {
    totals: {},
    itemsPendingChanges: [],
    pendingChanges: false,
  };
};

const getters = {
  items: (state) => state.totals['items'] || [],
  totalSegments: (state) => state.totals['total_segments'],
};

const mutations = {
  SET_TOTALS(state: TotalsState, totals: object) {
    state.totals = totals;
  },
  SET_PENDING_CHANGES(state: TotalsState, status: boolean) {
    state.pendingChanges = status;
  },
  APPEND_ITEM_PENDING_CHANGES(state: TotalsState, itemId: number)
  {
    if (state.itemsPendingChanges.indexOf(itemId) < 0) {
      state.itemsPendingChanges.push(itemId);
    }
  },
  REMOVE_ITEM_PENDING_CHANGES(state: TotalsState, itemId: number) {
    const itemIdIndex: number = state.itemsPendingChanges.indexOf(itemId);
    if (itemIdIndex >= 0) {
      state.itemsPendingChanges.splice(itemIdIndex, 1);
    }
  }
};

const actions = {
  async fetchCartTotals({ commit }): Promise<void> {
    commit('SET_PENDING_CHANGES', true);

    try {
      const { data: totals } = await this.$solarClient.get(`/api/checkout/totals`);
      commit('SET_TOTALS', totals);
    } catch (err) {
      console.error(err);
    }

    commit('SET_PENDING_CHANGES', false);
  },
};

const state: TotalsState = initialState();

const CheckoutTotals = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default CheckoutTotals;
